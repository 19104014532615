@import './variables';
@import "node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;600&display=swap');

body {
  font-family: 'Raleway', sans-serif;
  background-color: white;
  min-width: 280px;
}

.btn{
  border-radius: 7px;
}

.form-control {
  height: 44px;

  &:disabled, &[readonly] {
    background-color: rgba(219,219,219,.20);
  }

  &input{
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

.link-unstyled{
  text-decoration: none;
  color: inherit;

  &:hover{
    text-decoration: none;
    color: inherit;
  }
}

.page-card{
  background-color: white;
  padding: 30px;
  border-radius: 27px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 4px rgba(0,0,0,.15);
}

.page-card:last-child{
  margin-bottom: 0;
}

.page-card__title{
  margin-bottom: 1em;
  font-size: 1em;
  color: $black;
  font-weight: bolder;
}

.filter-form{

}

.filter-form__input{
  border-radius: 44px;
}

.btn{
  padding: 10px 20px;
}

.btn.semi-block{
  min-width: 250px;

  @media(max-width: map-get($grid-breakpoints, "sm")){
    min-width: 75%;
  }
}

@media(max-width: map-get($grid-breakpoints, "md")){
  .container--xs-full{
    padding-left: 0;
    padding-right: 0;
  }
}

.page-listing{
  background-color: white;
  margin-bottom: 30px;

  border: 1px solid rgba(0,0,0,.25);
}

.page-listing--unique{
  @media(max-width: map-get($grid-breakpoints, "md")){
    border: none;
  }
}

.page-listing:last-child{
  margin-bottom: 0;
  border-bottom: none;
}

.page-listing__item{
  background-color: white;
  padding: 15px;
  border-bottom: 1px solid rgba(0,0,0,.25);
}

.page-listing__item:last-child{
  margin-bottom: 0;
}

.no-records{
  padding: 50px 0;
  text-align: center;
}

.main-container{
  padding: 30px 20px;

  @media(max-width: map-get($grid-breakpoints, "md")){
    padding: 0;
  }
}

@media(max-width: map-get($grid-breakpoints, "sm")){
  .container--xs-full{
    padding-left: 0;
    padding-right: 0;
  }
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: darkgrey;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.input-group .input-group-text,
.form-control{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(50, 64, 72, 0.6);
  border-radius: 0;

  &:hover,
  &:active,
  &:focus{
    box-shadow: none;
  }

  &.is-invalid,
  & .input-group-text ~ .is-invalid{ //TODO: Selector para el text del addon cuando el input tiene is-invalid
    border-bottom: 1px solid rgba(255,0,0,.3);
  }
}

.input-group .input-group-text{
  padding: 0;
  font-size: 1.75em;
  color: rgba(50, 64, 72, 0.6);
}

.modal-header{
  border-bottom: none;
}

.modal-body{
  padding: 35px 70px 1em 70px;
}

.modal-footer{
  border-top: none;
  justify-content: center;
  padding-bottom: 70px;
}


.menu-user{
  display: flex;
}

.menu-user--vertical{
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.menu-user__data {
  flex: 1;
  padding: 5px 0;
}

.menu-user__data-email,
.menu-user__data-name{
  display: block;
  line-height: 20px;
}

.menu-user__data-email{
  color: rgba(255,255,255,.5);
  font-size: .85em;
}

.menu-user__data-name{
  font-weight: bolder;
  font-size: .95em;
}

.menu-user__avatar-container{
  border: 2px solid white;
  border-radius: 50%;
  flex-basis: 52px;
  width: 52px;
  margin-right: 15px;
  position: relative;

  &:hover {
    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      content: 'Cambiar';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.5);
      border-radius: 50%;
      cursor: pointer;
      color: $white;
      font-weight: bolder;
    }
  }
}

.menu-user__avatar{
  border: 2px solid $primary;
  border-radius: 50%;
  max-width: 48px;
  max-height: 48px;
  min-height: 48px;
  min-width: 48px;
  background-color: black;
}

.menu-user__avatar-container--big{
  flex-basis: 83px;
  width: 83px;
  border-width: 3px;

  .menu-user__avatar{
    border-width: 3px;
    max-width: 77px;
    max-height: 77px;
    min-height: 77px;
    min-width: 77px;
  }
}

.label-inputs{
  //color: rgba(75,75,75,.75);
  color: inherit;
}

.screen-title{
  font-weight: bolder;
}

.server-error{
  color: red;
  text-align: center;
}

.global-loading{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}